import React from "react"
import * as styles from "./custom-link.module.scss"
import { Link } from "gatsby"

const CustomLink = props => (
  <Link
    {...props}
    className={
      props.className
        ? `${props.className} ${
            props.theme === "dark"
              ? styles.customLinkDark
              : styles.customLinkLight
          }`
        : props.theme === "dark"
        ? styles.customLinkDark
        : styles.customLinkLight
    }
  >
    {props.children}
  </Link>
)

export default CustomLink
