import React, { useEffect, useState } from "react"
import * as styles from "./navbar.module.scss"
import { NavbarLink } from "../"
import { Link } from "gatsby"
import MediaQuery from "react-responsive"

const links = [
  { to: "/", text: "Home" },
  { to: "/about", text: "About" },
  { to: "/products", text: "Products" },
  { to: "/services", text: "Services" },
  { to: "/contact", text: "Contact" },
]
const Navbar = props => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      const breakpoint = 120
      if (scrolled >= breakpoint) {
        if (scrolled !== true) setScrolled(true)
      } else {
        if (scrolled !== false) setScrolled(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrolled])

  const [opened, setOpened] = useState(false)
  const toggleNavBar = () => {
    setOpened(!opened)
  }

  return (
    <div>
      <div
        className={
          !scrolled
            ? props.theme === "dark"
              ? styles.navbarDark
              : styles.navbarLight
            : styles.navbarScrolled
        }
      >
        <Link to="/">
          <div className={styles.logoContainer}>
            <span className={styles.logo}>S B Tan</span>
            <span className={styles.sublogo}>
              Public Accountants & Chartered Accountants, Singapore
            </span>
          </div>
        </Link>
        <MediaQuery query="(min-width:901px)">
          <div className={styles.linkContainer}>
            {links.map(link => (
              <NavbarLink
                theme={!scrolled ? props.theme : "light"}
                to={link.to}
              >
                {link.text}
              </NavbarLink>
            ))}
          </div>
        </MediaQuery>
        <MediaQuery query="(max-width:900px)">
          <div
            className={
              props.theme === "dark" && !scrolled
                ? opened
                  ? styles.hamburgerDarkOpened
                  : styles.hamburgerDark
                : opened
                ? styles.hamburgerLightOpened
                : styles.hamburgerLight
            }
            onClick={toggleNavBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </MediaQuery>
      </div>
      <MediaQuery query="(max-width:900px)">
        <div
          className={
            opened ? styles.toggleContainerOpened : styles.toggleContainerClosed
          }
        >
          {links.map(link => (
            <div className={styles.toggleLink}>
              <NavbarLink
                theme={!scrolled ? props.theme : "light"}
                to={link.to}
              >
                {link.text}
              </NavbarLink>
            </div>
          ))}
        </div>
      </MediaQuery>
    </div>
  )
}

export default Navbar
