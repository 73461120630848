import React from "react"
import * as styles from "./section.module.scss"
import WaveTop from "../../images/wave-top.svg"
import WaveBottom from "../../images/wave-bottom.svg"

const Section = props => (
  <div data-aos={props["data-aos"]}>
    {props.curve && <img src={WaveTop}></img>}
    <div
      className={
        props.className
          ? `${styles[props.align] || styles.section} ${props.className}`
          : styles[props.align] || styles.section
      }
    >
      {props.children}
    </div>
    {props.curve && <img src={WaveBottom}></img>}
  </div>
)

export default Section
