import React from "react"

import { Navbar } from "../"

import * as styles from "./layout.module.scss"

const Header = props => <Navbar theme={props.theme}></Navbar>

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.contactFooter}>
      <h3 className={styles.contactFooterTitle}>Contact us</h3>
      <div className={styles.contactFooterDetails}>
        118 Aljunied Avenue 2 #06-104<br></br>
        Singapore 380118<br></br>
        Tel: 68448626 <br></br>Fax: 68448627 <br></br>Email: admin@sbtan.com
      </div>
    </div>
  </div>
)

const Layout = props => (
  <div>
    <Header theme={props.theme}></Header>
    {props.children}
    <Footer></Footer>
  </div>
)

export default Layout
