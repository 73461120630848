import React from "react"

import * as styles from "./navbar-link.module.scss"

import { CustomLink } from "../"

const NavbarLink = props => (
  <CustomLink {...props} className={styles.navbarLink}>
    {props.children}
  </CustomLink>
)

export default NavbarLink
