import React from "react"
import { Image } from "../"

import * as styles from "./card.module.scss"

const Card = props => (
  <div className={styles.card}>
    {props.img && (
      <div className={styles.imgContainer}>
        <Image
          className={styles.img}
          src={props.img.src}
          alt={props.img.alt}
        ></Image>
      </div>
    )}
    <div className={styles.textContainer}>
      <h3 className={styles.title}>{props.title}</h3>
      <p className={styles.text}>{props.text}</p>
    </div>
  </div>
)

export default Card
