import React from "react"
import * as styles from "./hero.module.scss"
import { Image, TextContainer } from "../"

const Hero = props => (
  <div style={{ overflow: "auto" }}>
    <div
      {...props}
      className={
        props.className ? `${props.className} ${styles.hero}` : styles.hero
      }
    >
      <TextContainer
        className={styles.heroTextContainer}
        title={props.hero.title}
        text={props.hero.text}
        theme={props.theme}
      >
        {props.children}
      </TextContainer>
      <div className={styles.heroImage}>
        <Image src={props.image.src} alt={props.image.alt}></Image>
      </div>
    </div>
  </div>
)

export default Hero
