import React from "react"
import * as styles from "./text-container.module.scss"

const TextContainer = props => (
  <div
    {...props}
    className={
      props.className
        ? `${props.className} ${styles.textContainer}`
        : styles.textContainer
    }
  >
    <h3 className={props.theme === "dark" ? styles.titleDark : styles.title}>
      {props.title}
    </h3>
    <p className={styles.text}>{props.text}</p>
    {props.children}
  </div>
)

export default TextContainer
