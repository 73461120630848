import React from "react"
import * as styles from "./custom-button.module.scss"
import { CustomLink } from "../"

const CustomButton = props => (
  <button
    {...props}
    className={
      props.className
        ? `${styles.customButton} ${props.className}`
        : styles.customButton
    }
  >
    <CustomLink to={props.to} target={props.target}>
      {props.children}
    </CustomLink>
  </button>
)

export default CustomButton
