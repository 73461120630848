import React, { useState } from "react"
import { Image } from "../"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import "./carousel.styles.scss"
import * as styles from "./carousel.module.scss"

const CustomCarousel = props => {
  return (
    <Carousel>
      {props.pages.map(page => (
        <div className={styles.textContainer}>
          <div className={styles.titleContainer}>
            {page.logo && (
              <Image
                className={styles.logo}
                src={page.logo}
                alt={page.client}
              ></Image>
            )}
            <h3 className={styles.title}>{page.client}</h3>
          </div>

          <p className={styles.text}>{page.text}</p>
        </div>
      ))}
    </Carousel>
  )
}

export default CustomCarousel
