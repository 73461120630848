import React, { useState } from "react"
import * as styles from "./sidebar.module.scss"

import { useMediaQuery } from "react-responsive"

import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

const CustomDropdown = props => {
  const [selected, setSelected] = useState(props.defaultOption)

  const handleChange = option => {
    setSelected(option)
    props.setActive(option.value)
  }

  return (
    <Dropdown
      className={styles.sidebarDropdown}
      options={props.options}
      onChange={handleChange}
      value={selected}
      placeholder="Select an option"
    />
  )
}

const Sidebar = props => {
  const [active, setActive] = useState(0)

  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  })

  //   const arrayToObject = array => {
  //     return array.reduce((obj, item) => {
  //       return { ...obj, [array.indexOf(item)]: item }
  //     }, {})
  //   }
  const pagesDict = React.Children.map(
    props.children,
    child => child.props.name
  )

  const options = pagesDict.map((name, index) => {
    return { value: index, label: name }
  })
  const defaultOption = options[0]

  return (
    <div className={styles.sidebarContainer}>
      {isMobile ? (
        <CustomDropdown
          options={options}
          defaultOption={defaultOption}
          setActive={setActive}
        ></CustomDropdown>
      ) : (
        <div className={styles.sidebarLinkContainer}>
          {pagesDict.map((name, index) => (
            <div
              className={
                active === index ? styles.sidebarLinkActive : styles.sidebarLink
              }
              onClick={() => {
                setActive(index)
              }}
            >
              {name}
            </div>
          ))}
        </div>
      )}
      {React.Children.map(
        props.children,
        child =>
          pagesDict[active] === child.props.name && (
            <div className={styles.sidebarPage}>{child}</div>
          )
      )}
    </div>
  )
}

export default Sidebar
