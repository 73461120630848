// extracted by mini-css-extract-plugin
export var navbarDark = "navbar-module--navbarDark--2Li80";
export var navbarLight = "navbar-module--navbarLight--3MqLn";
export var navbarScrolled = "navbar-module--navbarScrolled--1eIbb";
export var logoContainer = "navbar-module--logoContainer--2JXlK";
export var logo = "navbar-module--logo--3mxC_";
export var sublogo = "navbar-module--sublogo--2ejDM";
export var linkContainer = "navbar-module--linkContainer--3smRV";
export var hamburgerDark = "navbar-module--hamburgerDark--J3DY1";
export var hamburgerDarkOpened = "navbar-module--hamburgerDarkOpened--oOUJy";
export var hamburgerLight = "navbar-module--hamburgerLight--s-_x9";
export var hamburgerLightOpened = "navbar-module--hamburgerLightOpened--NHV_-";
export var toggleContainerOpened = "navbar-module--toggleContainerOpened--15fI9";
export var toggleContainerClosed = "navbar-module--toggleContainerClosed--1lTah";
export var toggleLink = "navbar-module--toggleLink--2r2t-";